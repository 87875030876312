import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@shared/components/Button';
import { InputPasswordForm } from '@shared/components/Form/HookForm';
import { InputLabel } from '@shared/components/InputLabel';
import { useStepper } from '@shared/components/Stepper/hooks/useStepper';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';

import { useSignInMutation } from '@modules/auth/hooks/useSignInMutation';

import { useAthleteAuthorizeFlow } from '@modules/invites/contexts/AthleteAuthorizeFlow/hooks/useAthleteAuthorizeFlow';
import { AuthorizeAthleteStepFlow } from '@modules/invites/enums/AuthorizeAthleteStepFlow';
import {
  AthleteAuthorizationShareLoginSchema,
  IAthleteAuthorizationShareLoginSchema,
} from '@modules/invites/validations/AthleteAuthorizationShareLoginValidation';

export const AthleteAuthorizeShareLogin = (): ReactElement => {
  const { t } = useTranslation('invites');

  const { inviteDecoded } = useAthleteAuthorizeFlow();
  const { handleStepSelected } = useStepper();

  const { mutate: signIn, isPending } = useSignInMutation();

  const form = useForm<IAthleteAuthorizationShareLoginSchema>({
    resolver: zodResolver(AthleteAuthorizationShareLoginSchema),
  });

  const handleSubmit = async ({ password }: IAthleteAuthorizationShareLoginSchema): Promise<void> => {
    signIn(
      { username: inviteDecoded.riderEmail, password },
      {
        onError: error => {
          toast(error.response?.data.message, { type: 'error' });
        },
        onSuccess: ({ tokens }) => {
          localStorage.setItem(Storage.TOKENS, JSON.stringify(tokens));
          handleStepSelected(AuthorizeAthleteStepFlow.AUTHORIZATION);
        },
      },
    );
  };

  return (
    <section className="flex w-full flex-col items-center justify-center gap-2">
      {/* Title */}
      <Text className="mb-4 text-center text-lg font-bold text-black-400 lg:mb-8 lg:text-xl">
        {t('page_title_invite_register_login')}
      </Text>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex w-full flex-col gap-3">
          <div className="flex flex-col">
            <InputLabel>{t('email')}</InputLabel>
            <Text className="text-sm text-black-400">{inviteDecoded.riderEmail}</Text>
          </div>

          <InputPasswordForm name="password" label={t('password')} />

          <Button type="submit" isLoading={isPending} textOnLoading={t('accessing')}>
            {t('access')}
          </Button>
        </form>
      </FormProvider>
    </section>
  );
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ClientError } from '@shared/clients/http/commons/IClientError';
import { CompaniesClient } from '@shared/clients/http/CompaniesClient';

import { AuthorizeShare } from '../types/AthleteAuthorizeFlow/athleteAuthorize';
import { AuthorizeShareByInviteCodeRequest } from '../types/AthleteAuthorizeFlow/requests';

export function useAuthorizeRiderMutation() {
  return useMutation<AuthorizeShare, AxiosError<ClientError>, AuthorizeShareByInviteCodeRequest>({
    mutationKey: ['mutate:update:authorize-rider'],
    mutationFn: data => CompaniesClient.invitesAthletes().authorizeRider(data),
  });
}

import { tv } from 'tailwind-variants';

export const stepperHeaderItemVariants = tv({
  slots: {
    stepNumberContainer: 'flex h-2 w-2 items-center justify-center rounded-full border shadow-md lg:h-9 lg:w-9',
    stepNumberText: 'hidden select-none text-[18px] font-bold transition-colors duration-500 lg:flex',
    stepTitle: 'ml-2 select-none text-center text-xs font-semibold lg:flex lg:text-left',
  },
  variants: {
    stepEqual: {
      true: {
        stepNumberContainer:
          'border-green-500 bg-green-500 dark:border-gray-500 dark:bg-gray-500 lg:bg-green-50 dark:lg:bg-background-dark',
        stepNumberText: 'text-green-500 dark:text-white',
        stepTitle: 'text-green-500 dark:text-white',
      },
    },
    stepNext: {
      true: {
        stepNumberContainer: 'border-gray-200 dark:border-black-400',
        stepNumberText: 'text-gray-200 dark:text-black-400',
        stepTitle: 'text-gray-200 dark:text-black-400',
      },
    },
    stepPrevious: {
      true: {
        stepNumberContainer: 'border-green-500 bg-green-50 lg:bg-green-500',
        stepNumberText: 'text-green-100',
        stepTitle: 'text-green-100',
      },
    },
  },
});

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LogoAnimated from '@shared/assets/gifs/logo-animated-once-time.gif';
import { BackgroundSignIn } from '@shared/components/BackgroundSignIn/BackgroundSignIn';
import { CardContainer } from '@shared/components/Cards';
import { Page } from '@shared/components/Page';
import { PrivacyAndTerms } from '@shared/components/PrivacyAndTerms';
import { Text } from '@shared/components/Text';
import { Storage } from '@shared/constants/Storage';

import { SignUpForm } from '@modules/users/components/SignUp/SignUpForm';
import { VerifyAccountForm } from '@modules/users/components/SignUp/VerifyAccountForm';

import { useUsers } from '../contexts/Users/hooks/useUsers';

export const SignUp = (): ReactElement => {
  const { t } = useTranslation('users');

  const navigate = useNavigate();

  const { isRegistered, toggleRegistered } = useUsers();

  const onVerifySuccess = (): void => {
    navigate('/');
    toggleRegistered();
    sessionStorage.removeItem(Storage.EMAIL_REGISTER);
  };

  return (
    <Page className="flex h-full w-full items-center justify-center">
      <BackgroundSignIn />

      <CardContainer>
        {/* Logo */}
        <img src={LogoAnimated} alt="Equites" className="mb-2 h-12 w-auto lg:mb-4 lg:h-20" />

        {/* Title */}
        <Text className="mb-4 text-lg font-bold text-black-400 lg:mb-8 lg:text-xl">{t('register')}</Text>

        {/* Form data */}
        {isRegistered ? <VerifyAccountForm onVerifySuccess={onVerifySuccess} /> : <SignUpForm />}

        <PrivacyAndTerms />
      </CardContainer>
    </Page>
  );
};

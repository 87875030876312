/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ClientError } from '@shared/clients/http/commons/IClientError';
import { IdentityClient } from '@shared/clients/http/IdentityClient';

import { ValidateEmailAlreadyExistsRequest, ValidateEmailAlreadyExistsResponse } from '../types/Users/requests';

export function useValidateEmailAlreadyExistsMutation() {
  return useMutation<ValidateEmailAlreadyExistsResponse, AxiosError<ClientError>, ValidateEmailAlreadyExistsRequest>({
    mutationKey: ['mutate:create:validate-email-already-exists'],
    mutationFn: data => IdentityClient.user().validateEmailAlreadyExists(data),
  });
}

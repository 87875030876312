import { FC, ReactElement } from 'react';
import { PatternFormat } from 'react-number-format';

import { Slot } from '@radix-ui/react-slot';
import { twJoin } from 'tailwind-merge';

import { Text } from '../Text';
import {
  IInputMaskHelpTextProps,
  IInputMaskInputRootProps,
  IInputMaskRootProps,
  InputMaskIconProps,
  InputMaskInputProps,
} from './types';

// ------------------------------------------

/**
 * InputMask Root - Wrapper to all input
 */
const InputMaskRoot: FC<IInputMaskRootProps> = (props): ReactElement => {
  const { children, className } = props;

  return <div className={twJoin('flex flex-col', className)}>{children}</div>;
};

InputMaskRoot.displayName = 'InputMask.Root';

// ------------------------------------------

/**
 * InputMask Input Root - Wrapper to input field, to use with icon or not
 */
const InputMaskInputRoot: FC<IInputMaskInputRootProps> = ({ children, className }): ReactElement => {
  return (
    <div
      className={twJoin(
        'flex h-10 w-full items-center rounded-lg border border-gray-200 px-4 dark:border-black-400',
        className,
      )}
    >
      {children}
    </div>
  );
};

InputMaskInputRoot.displayName = 'InputMask.InputRoot';

// ------------------------------------------

/**
 * InputMask Icon - Icon to show before input field
 */
const InputMaskIcon: FC<InputMaskIconProps> = ({ children }): ReactElement => {
  return <Slot className="text-gray-400">{children}</Slot>;
};

InputMaskIcon.displayName = 'InputMask.Icon';

// ------------------------------------------

/**
 * InputMask HelpText - Text to show under input field with help or error messages
 */
const InputMaskHelpText: IInputMaskHelpTextProps = ({ children, className }): ReactElement => {
  return <Text className={twJoin('mt-[2px] text-xs text-gray-400', className)}>{children}</Text>;
};

InputMaskHelpText.displayName = 'InputMask.HelpText';

// ------------------------------------------

/**
 * InputMask Input - Input field
 */
const InputMaskInput: FC<InputMaskInputProps> = ({ className, getInputRef, ...props }): ReactElement => {
  return (
    <PatternFormat
      mask=" "
      getInputRef={getInputRef}
      valueIsNumericString
      className={twJoin(
        'w-full flex-1 bg-transparent text-md text-black-400 caret-green-500 outline-none placeholder:text-md placeholder:text-gray-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:placeholder:text-gray-200 dark:text-white dark:placeholder:text-gray-500 dark:disabled:text-gray-700 dark:disabled:placeholder:text-black-400',
        className,
      )}
      {...props}
    />
  );
};

InputMaskInput.displayName = 'InputMask.Input';

// ------------------------------------------

export const InputMask = {
  Root: InputMaskRoot,
  Input: InputMaskInput,
  InputRoot: InputMaskInputRoot,
  Icon: InputMaskIcon,
  HelpText: InputMaskHelpText,
};

import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@shared/components/Button';
import { InputForm } from '@shared/components/Form/HookForm';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';

import { useAuth } from '@modules/auth/contexts/Auth/hooks/useAuth';
import { useForgotPasswordMutation } from '@modules/auth/hooks/useForgotPasswordMutation';
import { ForgotPasswordSchema, IForgotPasswordSchema } from '@modules/auth/validations/ForgotPasswordValidation';

export const ForgotPasswordForm = (): ReactElement => {
  const { t } = useTranslation('auth');

  const form = useForm<IForgotPasswordSchema>({ resolver: zodResolver(ForgotPasswordSchema) });

  const { handleFormShowing } = useAuth();

  const { mutate: forgotPassword, isPending } = useForgotPasswordMutation();

  const handleSubmitForgotPassword = async (data: IForgotPasswordSchema): Promise<void> => {
    sessionStorage.setItem(Storage.EMAIL_FORGOT_PASSWORD, data.email);

    forgotPassword(data, {
      onError: error => {
        toast(error.response?.data.message, { type: 'error' });
      },
      onSuccess: ({ shouldConfirmAccount, shouldResetPassword }) => {
        if (shouldConfirmAccount) {
          handleFormShowing('CONFIRM_ACCOUNT');
        } else if (shouldResetPassword) {
          handleFormShowing('SET_PASSWORD');
        } else {
          handleFormShowing('CONFIRMATION_CODE_PASSWORD');
          toast(t('messages_email_sent_with_success'), { type: 'success' });
        }
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmitForgotPassword)} className="flex w-full flex-col">
        <div className="flex flex-col gap-4">
          <InputForm name="email" label={t('your_access_email')} placeholder={t('your_access_email')} />
        </div>

        <Button type="submit" className="mt-4" isLoading={isPending} textOnLoading={t('sending')}>
          {t('send')}
        </Button>

        <Link to="/" className="mt-3 text-center">
          <Text className="text-md text-green-500 transition-colors hover:text-green-400">{t('go_back')}</Text>
        </Link>
      </form>
    </FormProvider>
  );
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ClientError } from '@shared/clients/http/commons/IClientError';
import { IdentityClient } from '@shared/clients/http/IdentityClient';

import { ConfirmForgotPasswordRequest } from '../types/Auth/requests';

export function useConfirmForgotPasswordMutation() {
  return useMutation<void, AxiosError<ClientError>, ConfirmForgotPasswordRequest>({
    mutationKey: ['mutate:update:confirm-forgot-password'],
    mutationFn: data => IdentityClient.auth().confirmForgotPassword(data),
  });
}

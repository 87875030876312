import * as z from 'zod';

export const SignUpSchema = z.object({
  profile: z.enum(['ATHLETE', 'ASSOCIATION', 'COMPANY']),
  email: z.string({ required_error: 'email_required' }).email({ message: 'email_invalid' }),
  name: z.string({ required_error: 'name_required' }),
  password: z
    .string({ required_error: 'password_required' })
    .min(6, { message: 'password_min_length_six' })
    .regex(/([a-zA-Z]{1})/, { message: 'password_least_one_letter' }),
});

export type ISignUpSchema = z.infer<typeof SignUpSchema>;

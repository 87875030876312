import { FC, PropsWithChildren, ReactElement } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '@shared/clients/http/ReactQuery';

import { AppAuthProvider } from '@modules/auth/contexts';

import { AppInvitesProvider } from '@modules/invites/contexts';

import { AppUsersProvider } from '@modules/users/contexts';

export const EquitesAppProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppAuthProvider>
        <AppUsersProvider>
          <AppInvitesProvider>{children}</AppInvitesProvider>
        </AppUsersProvider>
      </AppAuthProvider>
    </QueryClientProvider>
  );
};

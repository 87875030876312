import { IAuthContext } from '@modules/auth/types/Auth/context';

import { useContextSelector } from 'use-context-selector';

import { AuthContext } from '..';

export function useAuth(): IAuthContext {
  const chooseFormShowing = useContextSelector(AuthContext, cs => cs.chooseFormShowing);
  const handleFormShowing = useContextSelector(AuthContext, cs => cs.handleFormShowing);

  return { chooseFormShowing, handleFormShowing };
}

import { AxiosInstance } from 'axios';

import { SignUpRequest, SignUpResponse } from '@modules/users/types/Users/requests';

export class SignUp {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async signUp(data: SignUpRequest): Promise<SignUpResponse> {
    const response = await this.instance.post(`/sign-up`, data);

    return response.data;
  }
}

export const signUp = (instance: AxiosInstance): SignUp => new SignUp(instance);

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ClientError } from '@shared/clients/http/commons/IClientError';
import { IdentityClient } from '@shared/clients/http/IdentityClient';

export function useResendCodeMutation() {
  return useMutation<void, AxiosError<ClientError>, { email: string }>({
    mutationKey: ['mutate:update:resend-code'],
    mutationFn: ({ email }) => IdentityClient.auth().resendCode(email),
  });
}

import { ReactElement } from 'react';

import LogoAnimated from '@shared/assets/gifs/logo-animated-once-time.gif';
import { BackgroundSignIn } from '@shared/components/BackgroundSignIn/BackgroundSignIn';
import { CardContainer } from '@shared/components/Cards';
import { Page } from '@shared/components/Page';
import { PrivacyAndTerms } from '@shared/components/PrivacyAndTerms';
import { Stepper } from '@shared/components/Stepper';

import {
  AthleteAuthorizeShareAlreadyShared,
  AthleteAuthorizeShareAuthorization,
  AthleteAuthorizeShareCheckEmail,
  AthleteAuthorizeShareCreateAccount,
  AthleteAuthorizeShareLogin,
  AthleteAuthorizeShareSuccess,
  AthleteAuthorizeShareVerifyAccount,
} from '@modules/invites/components/AthleteAuthorizeShare';
import { AuthorizeAthleteStepFlow } from '@modules/invites/enums/AuthorizeAthleteStepFlow';

export const AthleteAuthorizeShare = (): ReactElement => {
  return (
    <Page className="flex h-full w-full items-center justify-center">
      <BackgroundSignIn />

      <CardContainer>
        {/* Logo */}
        <img src={LogoAnimated} alt="Equites" className="mb-2 h-12 w-auto lg:mb-4 lg:h-20" />

        <Stepper.Root>
          <Stepper.Panel order={AuthorizeAthleteStepFlow.CHECK_EMAIL}>
            <AthleteAuthorizeShareCheckEmail />
          </Stepper.Panel>
          <Stepper.Panel order={AuthorizeAthleteStepFlow.CREATE_ACCOUNT}>
            <AthleteAuthorizeShareCreateAccount />
          </Stepper.Panel>
          <Stepper.Panel order={AuthorizeAthleteStepFlow.VERIFY_ACCOUNT}>
            <AthleteAuthorizeShareVerifyAccount />
          </Stepper.Panel>
          <Stepper.Panel order={AuthorizeAthleteStepFlow.LOGIN}>
            <AthleteAuthorizeShareLogin />
          </Stepper.Panel>
          <Stepper.Panel order={AuthorizeAthleteStepFlow.AUTHORIZATION}>
            <AthleteAuthorizeShareAuthorization />
          </Stepper.Panel>
          <Stepper.Panel order={AuthorizeAthleteStepFlow.SUCCESS}>
            <AthleteAuthorizeShareSuccess />
          </Stepper.Panel>
          <Stepper.Panel order={AuthorizeAthleteStepFlow.ALREADY_EXISTS}>
            <AthleteAuthorizeShareAlreadyShared />
          </Stepper.Panel>
        </Stepper.Root>

        <PrivacyAndTerms />
      </CardContainer>
    </Page>
  );
};

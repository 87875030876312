import { ReactElement, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PatternFormatProps } from 'react-number-format';

import { InputLabel } from '@shared/components/InputLabel';
import { InputMask as InputComponent } from '@shared/components/InputMask';

interface IInputProps extends PatternFormatProps {
  adornment?: ReactNode;
  name: string;
  label?: string;
  classNameRoot?: string;
  classNameInputRoot?: string;
}

export const InputMaskForm = (props: IInputProps): ReactElement => {
  const { adornment, name, label, classNameRoot, classNameInputRoot, required, ...rest } = props;

  const { t } = useTranslation('validations');

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={undefined}
      render={({ field: { value = '', onChange }, fieldState: { error } }) => (
        <InputComponent.Root className={classNameRoot}>
          {label && <InputLabel required={required}>{label}</InputLabel>}

          <InputComponent.InputRoot className={`${classNameInputRoot} ${error ? '!border-red-500' : ''}`}>
            <InputComponent.Input
              id={name}
              type="tel"
              value={value}
              onValueChange={event => onChange(event.value)}
              {...rest}
            />

            {adornment && <InputComponent.Icon>{adornment}</InputComponent.Icon>}
          </InputComponent.InputRoot>

          {error?.message && (
            <InputComponent.HelpText className={error ? '!text-red-500' : ''}>
              {t(error.message)}
            </InputComponent.HelpText>
          )}
        </InputComponent.Root>
      )}
    />
  );
};

import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { AthleteAuthorizationFlow } from '@modules/invites/constants/AthleteAuthorizationFlow';
import { InviteDecoded } from '@modules/invites/types/AthleteAuthorizeFlow/athleteAuthorize';
import { IAthleteAuthorizeFlowContext } from '@modules/invites/types/AthleteAuthorizeFlow/context';

const AthleteAuthorizeFlowContext = createContext({} as IAthleteAuthorizeFlowContext);
AthleteAuthorizeFlowContext.displayName = 'AthleteAuthorizeFlow';

const AthleteAuthorizeFlowProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [inviteDecoded, setInviteDecoded] = useState({} as InviteDecoded);
  const [currentAthleteAuthorizeStepFlow, setCurrentAthleteAuthorizeStepFlow] = useState<AthleteAuthorizationFlow>(
    AthleteAuthorizationFlow.VERIFY_EMAIL_ALREADY_EXISTS,
  );

  const handleAthleteAuthorizeStepFlow = useCallback((step: AthleteAuthorizationFlow) => {
    setCurrentAthleteAuthorizeStepFlow(step);
  }, []);

  const handleInviteDecoded = useCallback((data: InviteDecoded) => {
    setInviteDecoded(data);
  }, []);

  const contextValue = useMemo<IAthleteAuthorizeFlowContext>(
    () => ({ currentAthleteAuthorizeStepFlow, handleAthleteAuthorizeStepFlow, handleInviteDecoded, inviteDecoded }),
    [currentAthleteAuthorizeStepFlow, handleAthleteAuthorizeStepFlow, handleInviteDecoded, inviteDecoded],
  );

  return <AthleteAuthorizeFlowContext.Provider value={contextValue}>{children}</AthleteAuthorizeFlowContext.Provider>;
};

export { AthleteAuthorizeFlowContext, AthleteAuthorizeFlowProvider };

import './shared/styles/global.css';

import { ReactElement, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';

import { Fallback } from '@shared/components/Fallback';
import { i18n } from '@shared/configs/Translate';
import { Routes } from '@shared/routes';

export const App = (): ReactElement => (
  <Suspense fallback={<Fallback.Navigation />}>
    <I18nextProvider i18n={i18n}>
      <Routes />
    </I18nextProvider>
  </Suspense>
);

import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';

import { zodResolver } from '@hookform/resolvers/zod';
import { addMinutes } from 'date-fns';

import { Button } from '@shared/components/Button';
import { InputForm } from '@shared/components/Form/HookForm';
import { InputLabel } from '@shared/components/InputLabel';
import { useStepper } from '@shared/components/Stepper/hooks/useStepper';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';

import { useResendCodeMutation } from '@modules/auth/hooks/useResendCodeMutation';

import { useAthleteAuthorizeFlow } from '@modules/invites/contexts/AthleteAuthorizeFlow/hooks/useAthleteAuthorizeFlow';
import { AuthorizeAthleteStepFlow } from '@modules/invites/enums/AuthorizeAthleteStepFlow';
import {
  AthleteAuthorizationShareVerifyAccountSchema,
  IAthleteAuthorizationShareVerifyAccountSchema,
} from '@modules/invites/validations/AthleteAuthorizationShareVerifyAccountValidation';

import { useVerifyUserMutation } from '@modules/users/hooks/useVerifyUserMutation';

export const AthleteAuthorizeShareVerifyAccount = (): ReactElement => {
  const { t } = useTranslation('invites');

  const form = useForm<IAthleteAuthorizationShareVerifyAccountSchema>({
    resolver: zodResolver(AthleteAuthorizationShareVerifyAccountSchema),
  });

  const { inviteDecoded } = useAthleteAuthorizeFlow();
  const { handleStepSelected } = useStepper();

  const { mutate: resendCode, isPending: isLoadingResendCode } = useResendCodeMutation();
  const { mutate: verifyUser, isPending: isLoadingVerifyUser } = useVerifyUserMutation();

  const { isRunning, minutes, seconds, start } = useTimer({
    autoStart: false,
    expiryTimestamp: addMinutes(new Date(), 1),
  });

  const getResendCodeLabel = (): string => {
    return isRunning
      ? `${t('resend_code')} ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      : t('resend_code');
  };

  const onResendCode = async (): Promise<void> => {
    start();

    resendCode(
      { email: inviteDecoded.riderEmail },
      {
        onError: error => {
          toast(error.response?.data.message, { type: 'error' });
        },
        onSuccess: () => {
          toast(t('messages_resend_code_success'), { type: 'success' });
        },
      },
    );
  };

  const handleSubmit = async ({ code }: IAthleteAuthorizationShareVerifyAccountSchema): Promise<void> => {
    verifyUser(
      { code, email: inviteDecoded.riderEmail },
      {
        onError: error => {
          toast(error.response?.data.message, { type: 'error' });
        },
        onSuccess: () => {
          handleStepSelected(AuthorizeAthleteStepFlow.LOGIN);
        },
      },
    );
  };

  return (
    <section className="flex w-full flex-col items-center justify-center gap-2">
      {/* Title */}
      <Text className="mb-4 text-center text-lg font-bold text-black-400 lg:mb-8 lg:text-xl">
        {t('page_title_invite_register_verify_account')}
      </Text>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex w-full flex-col">
          <section className="flex flex-col gap-3">
            <div className="flex flex-col">
              <InputLabel>{t('name')}</InputLabel>
              <Text className="text-sm text-black-400">{inviteDecoded.riderName}</Text>
            </div>

            <div className="flex flex-col">
              <InputLabel>{t('email')}</InputLabel>
              <Text className="text-sm text-black-400">{inviteDecoded.riderEmail}</Text>
            </div>

            <div className="flex flex-col">
              <InputLabel>{t('password')}</InputLabel>
              <Text className="text-sm text-black-400">******</Text>
            </div>

            <InputForm name="code" label={t('verify_code')} placeholder={t('check_your_email')} />
          </section>

          <Button
            variant="tertiary"
            className="mb-3 mt-2"
            disabled={isRunning}
            isLoading={isLoadingResendCode}
            textOnLoading={t('sending')}
            onClick={onResendCode}
          >
            {getResendCodeLabel()}
          </Button>

          <Button type="submit" isLoading={isLoadingVerifyUser} textOnLoading={t('confirming')}>
            {t('confirm_account')}
          </Button>
        </form>
      </FormProvider>
    </section>
  );
};

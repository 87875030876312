import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import LogoAnimated from '@shared/assets/gifs/logo-animated-once-time.gif';
import { BackgroundSignIn } from '@shared/components/BackgroundSignIn/BackgroundSignIn';
import { Button } from '@shared/components/Button';
import { CardContainer } from '@shared/components/Cards';
import { InputPasswordForm } from '@shared/components/Form/HookForm';
import { InputLabel } from '@shared/components/InputLabel';
import { Page } from '@shared/components/Page';
import { PrivacyAndTerms } from '@shared/components/PrivacyAndTerms';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';

import { ISetPasswordSchema, SetPasswordSchema } from '@modules/auth/validations/SetPasswordValidation';

import { useFetchEmailByExternalUsername } from '@modules/users/hooks/useFetchEmailByExternalUsername';

import { useSetPasswordMutation } from '../hooks/useSetPasswordMutation';

type RouteParams = {
  externalUsername: string;
};

export const RegisterPassword = (): ReactElement => {
  const { externalUsername } = useParams<keyof RouteParams>();

  const { t } = useTranslation('auth');

  const navigate = useNavigate();

  const { data: userEmail } = useFetchEmailByExternalUsername({ externalUsername: externalUsername ?? '' });
  const { mutate: setPassword, isPending } = useSetPasswordMutation();

  const form = useForm<ISetPasswordSchema>({ resolver: zodResolver(SetPasswordSchema) });

  const handleSubmitConfirm = (data: ISetPasswordSchema): void => {
    if (userEmail)
      setPassword(
        { email: userEmail.email, password: data.password },
        {
          onError: error => {
            toast(error.response?.data.message, { type: 'error' });
          },
          onSuccess: () => {
            navigate('/');
            toast(t('messages_password_changed_with_success'), { type: 'success' });
          },
        },
      );
  };

  return (
    <Page className="flex h-full w-full items-center justify-center">
      <BackgroundSignIn />

      <CardContainer>
        {/* Logo */}
        <img src={LogoAnimated} alt="Equites" className="mb-2 h-12 w-auto lg:mb-4 lg:h-20" />

        {/* Title */}
        <Text className="mb-4 text-md font-bold text-black-400 lg:mb-8 lg:text-xl">{t('register_password')}</Text>

        {/* Form data */}
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmitConfirm)} className="flex w-full flex-col">
            <div className="flex flex-col gap-3">
              <div>
                <InputLabel>{`${t('your_access_email')}:`}</InputLabel>
                <Text className="text-sm text-black-400">{userEmail?.email}</Text>
              </div>

              <InputPasswordForm name="password" label={t('new_password')} placeholder={t('new_password')} />

              <InputPasswordForm
                name="passwordConfirmation"
                label={t('confirm_your_password')}
                placeholder={t('confirm_your_password')}
              />
            </div>

            <Button type="submit" className="mt-4" isLoading={isPending} textOnLoading={t('saving')}>
              {t('save')}
            </Button>
          </form>
        </FormProvider>

        <PrivacyAndTerms />
      </CardContainer>
    </Page>
  );
};

import { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@shared/components/Button';
import { InputMaskForm, InputPasswordForm } from '@shared/components/Form/HookForm';
import { InputLabel } from '@shared/components/InputLabel';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';

import { useConfirmForgotPasswordMutation } from '@modules/auth/hooks/useConfirmForgotPasswordMutation';
import {
  ConfirmForgotPasswordSchema,
  IConfirmForgotPasswordSchema,
} from '@modules/auth/validations/ConfirmForgotPasswordValidation';

export const ConfirmForgotPasswordForm = (): ReactElement => {
  const { t } = useTranslation('auth');

  const navigate = useNavigate();

  const form = useForm<IConfirmForgotPasswordSchema>({ resolver: zodResolver(ConfirmForgotPasswordSchema) });

  const { mutate: confirmForgotPassword, isPending } = useConfirmForgotPasswordMutation();

  const handleSubmitConfirm = async (data: IConfirmForgotPasswordSchema): Promise<void> => {
    confirmForgotPassword(
      { ...data, email: sessionStorage.getItem(Storage.EMAIL_FORGOT_PASSWORD) ?? '' },
      {
        onError: error => {
          toast(error.response?.data.message, { type: 'error' });
        },
        onSuccess: () => {
          navigate('/');
          toast(`${t('messages_password_changed_with_success')}!`, { type: 'success' });
        },
      },
    );
  };

  // Init fields
  useEffect(() => {
    const email = sessionStorage.getItem(Storage.EMAIL_FORGOT_PASSWORD);

    if (email) {
      form.reset({ email });
    }
  }, [form]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmitConfirm)} className="flex w-full flex-col">
        <div className="flex flex-col gap-3">
          <div>
            <InputLabel>{`${t('your_access_email')}:`}</InputLabel>
            <Text className="text-sm text-black-400">{sessionStorage.getItem(Storage.EMAIL_FORGOT_PASSWORD)}</Text>
          </div>

          <InputMaskForm
            name="code"
            format="######"
            label={t('confirmation_code')}
            placeholder={t('confirmation_code')}
          />

          <InputPasswordForm name="password" label={t('new_password')} placeholder={t('new_password')} />
        </div>

        <Button type="submit" className="mt-4" isLoading={isPending} textOnLoading={t('saving')}>
          {t('save')}
        </Button>
      </form>
    </FormProvider>
  );
};

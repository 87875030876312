interface IUrlConfig {
  athletesApiUrl: string;
  clientAssociationsUrl: string;
  clientAthletesUrl: string;
  clientCompaniesUrl: string;
  companiesApiUrl: string;
  identityApiUrl: string;
}

export const UrlConfig: IUrlConfig = {
  athletesApiUrl: import.meta.env.VITE_ATHLETES_API_URL ?? 'http://localhost:3335',
  clientAssociationsUrl: import.meta.env.VITE_CLIENT_ASSOCIATIONS_URL ?? 'http://localhost:5176',
  clientAthletesUrl: import.meta.env.VITE_CLIENT_ATHLETES_URL ?? 'http://localhost:5173',
  clientCompaniesUrl: import.meta.env.VITE_CLIENT_COMPANIES_URL ?? 'http://localhost:5175',
  companiesApiUrl: import.meta.env.VITE_COMPANIES_API_URL ?? 'http://localhost:3337',
  identityApiUrl: import.meta.env.VITE_IDENTITY_API_URL ?? 'http://localhost:3334',
};

import { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputLabel } from '@shared/components/InputLabel';
import { InputText as InputComponent } from '@shared/components/InputText';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  adornment?: ReactNode;
  name: string;
  label?: string;
  classNameRoot?: string;
}

export const InputForm = ({ adornment, name, label, classNameRoot, required, ...props }: IInputProps): ReactElement => {
  const { t } = useTranslation('validations');

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value = '', onChange }, fieldState: { error } }) => (
        <InputComponent.Root className={classNameRoot}>
          {label && <InputLabel required={required}>{label}</InputLabel>}

          <InputComponent.InputRoot className={error ? '!border-red-500' : ''}>
            <InputComponent.Input id={name} value={value} onChange={onChange} {...props} />

            {adornment && <InputComponent.Icon>{adornment}</InputComponent.Icon>}
          </InputComponent.InputRoot>

          {error?.message && (
            <InputComponent.HelpText className="!text-red-500">{t(error.message)}</InputComponent.HelpText>
          )}
        </InputComponent.Root>
      )}
    />
  );
};

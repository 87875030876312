import { FC, PropsWithChildren, ReactElement } from 'react';

import { Check } from '@phosphor-icons/react';

import { Text } from '../Text';
import { StepperProvider } from './contexts/StepperContext';
import { useStepper } from './hooks/useStepper';
import { stepperHeaderItemVariants } from './styles';
import { IStepperHeaderItemProps, IStepperPanelProps, IStepperRootProps } from './types';

// ------------------------------------------

/**
 * Stepper Root - Wrapper to component
 */
const StepperRoot: IStepperRootProps = ({ children }): ReactElement => {
  return (
    <StepperProvider>
      <div className="w-full">{children}</div>
    </StepperProvider>
  );
};

StepperRoot.displayName = 'Stepper.Root';

// ------------------------------------------

/**
 * Stepper Header - Wrapper to show items of the tabs
 */
const StepperHeader: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <ul role="tablist" className="flex flex-row justify-evenly lg:justify-start">
      {children}
    </ul>
  );
};

StepperHeader.displayName = 'Stepper.Header';

// ------------------------------------------

/**
 * Stepper Header Item - Item of the tabs
 */
const StepperHeaderItem: FC<PropsWithChildren<IStepperHeaderItemProps>> = (props): ReactElement => {
  const { onClick, order, title } = props;

  const { stepSelected } = useStepper();

  const { stepNumberContainer, stepNumberText, stepTitle } = stepperHeaderItemVariants({
    stepEqual: order === stepSelected,
    stepNext: order > stepSelected,
    stepPrevious: order < stepSelected,
  });

  const handleClickItem = (): void => {
    if (onClick) onClick();
  };

  return (
    <li role="presentation" onClick={handleClickItem} className="flex items-center gap-2 lg:flex-row lg:gap-0">
      {/* Divider */}
      {order > 1 && <div className="mx-4 hidden h-[1px] w-8 bg-gray-200 dark:bg-black-400 lg:flex" />}

      {/* Step number */}
      <div className={stepNumberContainer()}>
        {order < stepSelected ? (
          <Check size={20} className="hidden text-white lg:flex" weight="bold" />
        ) : (
          <Text className={stepNumberText()}>{order}</Text>
        )}
      </div>

      {/* Step title */}
      <Text
        data-selected={order === stepSelected}
        className={stepTitle({ className: 'hidden data-[selected=true]:flex' })}
      >
        {title}
      </Text>
    </li>
  );
};

StepperHeaderItem.displayName = 'Stepper.HeaderItem';

// ------------------------------------------

/**
 * Stepper Panel - Wrapper to panels to show reference a stepper item
 */
const StepperPanel: FC<PropsWithChildren<IStepperPanelProps>> = ({ children, order }): ReactElement | null => {
  const { stepSelected } = useStepper();

  const isActive = stepSelected === order;

  return isActive ? <div className="h-full w-full">{children}</div> : null;
};

StepperPanel.displayName = 'Stepper.Panel';

// ------------------------------------------

export const Stepper = {
  Root: StepperRoot,
  Header: StepperHeader,
  HeaderItem: StepperHeaderItem,
  Panel: StepperPanel,
};

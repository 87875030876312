import { tv } from 'tailwind-variants';

export const avatarVariants = tv({
  slots: {
    container: 'flex cursor-pointer items-center justify-center bg-gray-200',
    image: 'object-fill',
  },
  variants: {
    mode: {
      ROUNDED: {
        container: 'rounded-full',
        image: 'rounded-full',
      },
      SQUARE: {
        container: 'rounded-lg',
        image: 'rounded-lg',
      },
    },
    size: {
      '3xs': {
        container: 'h-8 w-8',
        image: 'h-8 w-8',
      },
      xxs: {
        container: 'h-11 w-11',
        image: 'h-11 w-11',
      },
      xs: {
        container: 'h-14 w-14',
        image: 'h-14 w-14',
      },
      sm: {
        container: 'h-20 w-20',
        image: 'h-20 w-20',
      },
      md: {
        container: 'h-28 w-28',
        image: 'h-28 w-28',
      },
      lg: {
        container: 'h-44 w-44',
        image: 'h-44 w-44',
      },
      xl: {
        container: 'h-56 w-56',
        image: 'h-56 w-56',
      },
      full: {
        container: 'h-full w-full',
        image: 'h-full w-full',
      },
    },
  },
  defaultVariants: {
    mode: 'SQUARE',
  },
});

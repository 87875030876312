import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { signUp, SignUp } from '@modules/users/services/SignUp';

import { athletesApiConfig } from './config';

export class AthletesAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = athletesApiConfig(params).instance();
  }

  signUp(): SignUp {
    return signUp(this.instance);
  }
}

export const AthletesFactoryAPI = (params: IClientConfigProps): AthletesAPI => new AthletesAPI(params);

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useQuery } from '@tanstack/react-query';

import { IdentityClient } from '@shared/clients/http/IdentityClient';

import { GetEmailByExternalUsernameResponse } from '../types/Users/requests';

type IProps = {
  externalUsername: string;
};

export function useFetchEmailByExternalUsername({ externalUsername }: IProps) {
  return useQuery<GetEmailByExternalUsernameResponse>({
    enabled: Boolean(externalUsername),
    queryKey: ['email:from-external-username', { externalUsername }],
    queryFn: () => IdentityClient.user().getEmailByExternalUsername(externalUsername),
    staleTime: 1000 * 60 * 30, // 30 minutes
  });
}

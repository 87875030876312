/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ClientError } from '@shared/clients/http/commons/IClientError';
import { IdentityClient } from '@shared/clients/http/IdentityClient';
import { Storage } from '@shared/constants/Storage';
import { sleep } from '@shared/utils/SleepFunction';
import { usePersistedState } from '@shared/utils/usePersistedState';

import { CurrentUserDTO } from '@modules/users/types/Users/users';

import { Tokens } from '../types/Auth/auth';
import { SignInRequest } from '../types/Auth/requests';

type SignInMutationResponse = {
  tokens: Tokens;
  user: CurrentUserDTO;
};

export function useSignInMutation() {
  const [, setTokens] = usePersistedState<Tokens | null>(Storage.TOKENS, null);

  return useMutation<SignInMutationResponse, AxiosError<ClientError>, SignInRequest>({
    mutationKey: ['mutate:create:session'],
    mutationFn: async data => {
      const tokens = await IdentityClient.auth().signIn(data);

      setTokens(tokens);

      await sleep(150);

      const currentUser = await IdentityClient.user().getCurrentUser();

      setTokens(null);

      return { user: currentUser, tokens };
    },
  });
}

import { FC, ReactElement } from 'react';

import { Slot } from '@radix-ui/react-slot';
import { twJoin } from 'tailwind-merge';

import { Text } from '../Text';
import {
  IInputTextHelpTextProps,
  IInputTextInputRootProps,
  IInputTextRootProps,
  InputTextIconProps,
  InputTextInputProps,
} from './types';

// ------------------------------------------

/**
 * InputText Root - Wrapper to all input
 */
const InputTextRoot: FC<IInputTextRootProps> = (props): ReactElement => {
  const { children, className } = props;

  return <div className={twJoin('flex flex-col', className)}>{children}</div>;
};

InputTextRoot.displayName = 'InputText.Root';

// ------------------------------------------

/**
 * InputText Input Root - Wrapper to input field, to use with icon or not
 */
const InputTextInputRoot: FC<IInputTextInputRootProps> = ({ children, className }): ReactElement => {
  return (
    <div
      className={twJoin(
        'flex h-10 w-full items-center rounded-lg border border-gray-200 px-4 dark:border-black-400',
        className,
      )}
    >
      {children}
    </div>
  );
};

InputTextInputRoot.displayName = 'InputText.InputRoot';

// ------------------------------------------

/**
 * InputText Icon - Icon to show before input field
 */
const InputTextIcon: FC<InputTextIconProps> = ({ children }): ReactElement => {
  return <Slot className="text-gray-400">{children}</Slot>;
};

InputTextIcon.displayName = 'InputText.Icon';

// ------------------------------------------

/**
 * InputText HelpText - Text to show under input field with help or error messages
 */
const InputTextHelpText: IInputTextHelpTextProps = ({ children, className }): ReactElement => {
  return <Text className={twJoin('mt-[2px] text-xs text-gray-400', className)}>{children}</Text>;
};

InputTextHelpText.displayName = 'InputText.HelpText';

// ------------------------------------------

/**
 * InputText Input - Input field
 */
const InputTextInput: FC<InputTextInputProps> = ({ className, type = 'text', inputRef, ...props }): ReactElement => {
  return (
    <input
      ref={inputRef}
      type={type}
      className={twJoin(
        'w-full flex-1 bg-transparent text-md text-black-400 caret-green-500 outline-none placeholder:text-md placeholder:text-gray-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:placeholder:text-gray-200 dark:text-white dark:placeholder:text-gray-500 dark:disabled:text-gray-700 dark:disabled:placeholder:text-black-400',
        className,
      )}
      {...props}
    />
  );
};

InputTextInput.displayName = 'InputText.Input';

// ------------------------------------------

export const InputText = {
  Root: InputTextRoot,
  Input: InputTextInput,
  InputRoot: InputTextInputRoot,
  Icon: InputTextIcon,
  HelpText: InputTextHelpText,
};

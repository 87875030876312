import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { IStepperContext } from '../types';

const StepperContext = createContext<IStepperContext>({} as IStepperContext);

const StepperProvider: FC<PropsWithChildren> = ({ children }) => {
  const [stepSelected, setStepSelected] = useState(1);

  const handleStepSelected = useCallback((selected: number) => {
    setStepSelected(current => (current !== selected ? selected : current));
  }, []);

  const toPreviousStep = useCallback(() => {
    setStepSelected(current => current - 1);
  }, []);

  const toNextStep = useCallback(() => {
    setStepSelected(current => current + 1);
  }, []);

  const contextValue = useMemo<IStepperContext>(
    () => ({ stepSelected, handleStepSelected, toNextStep, toPreviousStep }),
    [handleStepSelected, stepSelected, toNextStep, toPreviousStep],
  );

  return <StepperContext.Provider value={contextValue}>{children}</StepperContext.Provider>;
};

export { StepperContext, StepperProvider };

import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { ChooseFormShowing } from '@modules/auth/types/Auth/auth';
import { IAuthContext } from '@modules/auth/types/Auth/context';

const AuthContext = createContext({} as IAuthContext);
AuthContext.displayName = 'Auth';

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [chooseFormShowing, setChooseFormShowing] = useState<ChooseFormShowing>('EMAIL_FORM');

  const handleFormShowing = useCallback((choose: ChooseFormShowing) => {
    setChooseFormShowing(choose);
  }, []);

  const contextValue = useMemo<IAuthContext>(
    () => ({ chooseFormShowing, handleFormShowing }),
    [chooseFormShowing, handleFormShowing],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };

import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconProps } from '@phosphor-icons/react';

import { InputLabel } from '@shared/components/InputLabel';
import { Select as SelectComponent } from '@shared/components/Select';
import { ISelectOption, ISelectProps } from '@shared/components/Select/types';

interface ISelectFormProps extends ISelectProps {
  name: string;
  options: ISelectOption[];
  classNameRoot?: string;
  clearable?: boolean;
  icon?: React.ComponentType<IconProps>;
  label?: string;
  onClearOption?: () => void;
  onSelectOption?: (option: ISelectOption) => void;
  optionKey?: string;
  optionLabel?: string;
  optionValue?: string;
  placeholder?: string;
  required?: boolean;
  withAvatar?: boolean;
  withFilter?: boolean;
}

export const SelectForm = (props: ISelectFormProps): ReactElement => {
  const {
    name,
    clearable,
    onClearOption,
    classNameRoot,
    options,
    optionLabel = 'label',
    optionValue = 'value',
    optionKey,
    onSelectOption,
    label,
    disable,
    icon: Icon,
    withAvatar,
    withFilter = false,
    required,
    ...rest
  } = props;

  const rootProps = { optionLabel, optionValue, options, disable };

  const { t } = useTranslation('validations');

  const { control, setValue } = useFormContext();

  const handleSelectOption = (option: ISelectOption): void => {
    if (onSelectOption) {
      onSelectOption(option);
    }
  };

  const handleClear = (): void => {
    if (clearable) {
      setValue(name, null);
    }

    if (onClearOption) {
      onClearOption();
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={undefined}
      render={({ field: { value = '', onChange }, fieldState: { error } }) => (
        <SelectComponent.Root className={classNameRoot} {...rootProps}>
          {label && <InputLabel required={required}>{label}</InputLabel>}

          <SelectComponent.Content>
            <SelectComponent.Input
              id={name}
              value={value}
              classNameContainer={error ? '!border-red-500' : ''}
              clearable={clearable}
              onClearOption={handleClear}
              {...rest}
            />

            <SelectComponent.Options
              onSelectOption={option => {
                handleSelectOption(option);
                onChange(option[optionValue]);
              }}
              optionKey={optionKey}
              icon={Icon}
              withAvatar={withAvatar}
              withFilter={withFilter}
            />
          </SelectComponent.Content>

          {error?.message && (
            <SelectComponent.HelpText className={error ? '!text-red-500' : ''}>
              {t(error.message)}
            </SelectComponent.HelpText>
          )}
        </SelectComponent.Root>
      )}
    />
  );
};

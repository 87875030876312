import * as z from 'zod';

export const ForgotPasswordSchema = z.object({
  email: z
    .string({ required_error: 'email_required' })
    .min(1, { message: 'email_required' })
    .email({ message: 'email_invalid' }),
});

export type IForgotPasswordSchema = z.infer<typeof ForgotPasswordSchema>;

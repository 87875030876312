import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { invitesAthletes, InvitesAthletes } from '@modules/invites/services/Athletes';

import { signUp, SignUp } from '@modules/users/services/SignUp';

import { companiesApiConfig } from './config';

export class CompaniesAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = companiesApiConfig(params).instance();
  }

  invitesAthletes(): InvitesAthletes {
    return invitesAthletes(this.instance);
  }

  signUp(): SignUp {
    return signUp(this.instance);
  }
}

export const CompaniesFactoryAPI = (params: IClientConfigProps): CompaniesAPI => new CompaniesAPI(params);

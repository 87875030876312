import { RouteObject } from 'react-router-dom';

import { ForgotPassword } from '@modules/auth/pages/ForgotPassword';
import { RegisterPassword } from '@modules/auth/pages/RegisterPassword';
import { SignIn } from '@modules/auth/pages/SignIn';

import { AthleteAuthorizeShare } from '@modules/invites/pages/AthleteAuthorizeShare';

import { SignUp } from '@modules/users/pages/SignUp';

export class EquitesRoutes {
  public static publicRoutes: RouteObject[] = [
    // Auth
    { path: '/', element: <SignIn /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/register-password/users/:externalUsername', element: <RegisterPassword /> },

    // Invites
    { path: '/sign-up/athletes/invites/:inviteCode', element: <AthleteAuthorizeShare /> },

    // Users
    { path: '/sign-up', element: <SignUp /> },
  ];
}

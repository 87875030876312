import { useContextSelector } from 'use-context-selector';

import { AthleteAuthorizeFlowContext } from '@modules/invites/contexts/AthleteAuthorizeFlow';
import { IAthleteAuthorizeFlowContext } from '@modules/invites/types/AthleteAuthorizeFlow/context';

export function useAthleteAuthorizeFlow(): IAthleteAuthorizeFlowContext {
  const currentAthleteAuthorizeStepFlow = useContextSelector(
    AthleteAuthorizeFlowContext,
    cs => cs.currentAthleteAuthorizeStepFlow,
  );
  const inviteDecoded = useContextSelector(AthleteAuthorizeFlowContext, cs => cs.inviteDecoded);
  const handleAthleteAuthorizeStepFlow = useContextSelector(
    AthleteAuthorizeFlowContext,
    cs => cs.handleAthleteAuthorizeStepFlow,
  );
  const handleInviteDecoded = useContextSelector(AthleteAuthorizeFlowContext, cs => cs.handleInviteDecoded);

  return {
    currentAthleteAuthorizeStepFlow,
    inviteDecoded,
    handleAthleteAuthorizeStepFlow,
    handleInviteDecoded,
  };
}

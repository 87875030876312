import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { IUsersContext } from '@modules/users/types/Users/context';

const UsersContext = createContext({} as IUsersContext);
UsersContext.displayName = 'Users';

const UsersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isRegistered, setIsRegistered] = useState(false);

  const toggleRegistered = useCallback(() => {
    setIsRegistered(current => !current);
  }, []);

  const contextValue = useMemo<IUsersContext>(
    () => ({ isRegistered, toggleRegistered }),
    [isRegistered, toggleRegistered],
  );

  return <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>;
};

export { UsersContext, UsersProvider };

import { Buffer } from 'buffer';

export class Base64Util {
  public static encode(str: string): string {
    return Buffer.from(str).toString('base64');
  }

  public static decode(str: string): string {
    return Buffer.from(str, 'base64').toString('utf-8');
  }
}

import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { ISelectContext, ISelectOption, ISelectProviderProps } from '../types';

export type TabMode = 'UNDERLINE' | 'BOX';

const SelectContext = createContext<ISelectContext>({} as ISelectContext);

const SelectProvider: FC<PropsWithChildren<ISelectProviderProps>> = props => {
  const { children, label = 'label', value = 'value', options = [], disable = false } = props;

  const [isOpened, setOpened] = useState(false);
  const [option, setOption] = useState({} as ISelectOption);
  const [opts, setOpts] = useState<ISelectOption[]>(options);

  const toggleSelectOpen = useCallback(() => {
    setOpened(current => !current);
  }, []);

  const closeSelectOpen = useCallback(() => {
    setOpened(false);
    setOpts(options);
  }, [options]);

  const handleOptionChange = useCallback((selected: ISelectOption) => {
    setOption(selected);
  }, []);

  const handleFilterOptions = useCallback(
    (text: string) => {
      setOpts(options.filter(opt => opt[label].toLowerCase().includes(text.toLowerCase())));
    },
    [label, options],
  );

  const contextValue = useMemo<ISelectContext>(
    () => ({
      handleOptionChange,
      isOpened,
      toggleSelectOpen,
      option,
      label,
      value,
      closeSelectOpen,
      options: opts,
      disable,
      handleFilterOptions,
    }),
    [
      closeSelectOpen,
      disable,
      handleFilterOptions,
      handleOptionChange,
      label,
      isOpened,
      option,
      opts,
      toggleSelectOpen,
      value,
    ],
  );

  return <SelectContext.Provider value={contextValue}>{children}</SelectContext.Provider>;
};

export { SelectContext, SelectProvider };

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import queryString from 'query-string';

import { Button } from '@shared/components/Button';
import { Text } from '@shared/components/Text';
import { UrlConfig } from '@shared/configs/URL';
import { Storage } from '@shared/constants/Storage';

import { Tokens } from '@modules/auth/types/Auth/auth';

export const AthleteAuthorizeShareAlreadyShared = (): ReactElement => {
  const { t } = useTranslation('invites');

  const getUrlToAccess = (): string => {
    const tokensStr = localStorage.getItem(Storage.TOKENS);

    if (tokensStr) {
      const tokens = JSON.parse(tokensStr) as Tokens;
      return `${UrlConfig.clientAthletesUrl}/auth?${queryString.stringify(tokens)}`;
    }

    return '/';
  };

  return (
    <section className="flex w-full flex-col items-center justify-center">
      {/* Title */}
      <Text className="text-center text-lg font-bold text-black-400 lg:text-xl">
        {t('page_title_invite_register_already_shared')}
      </Text>

      {/* Description */}
      <Text className="mb-6 mt-2 text-center text-sm leading-tight text-gray-500">
        {t('share_data_already_shared_description')}
      </Text>

      <Button className="text-center" asChild>
        <Link to={getUrlToAccess()}>{t('access_equites')}</Link>
      </Button>
    </section>
  );
};

import { IUsersContext } from '@modules/users/types/Users/context';

import { useContextSelector } from 'use-context-selector';

import { UsersContext } from '..';

export function useUsers(): IUsersContext {
  const isRegistered = useContextSelector(UsersContext, cs => cs.isRegistered);
  const toggleRegistered = useContextSelector(UsersContext, cs => cs.toggleRegistered);

  return { isRegistered, toggleRegistered };
}

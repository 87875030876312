import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { auth, Auth } from '@modules/auth/services/Auth';

import { user, User } from '@modules/users/services/Users';

import { identityApiConfig } from './config';

export class IdentityAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = identityApiConfig(params).instance();
  }

  auth(): Auth {
    return auth(this.instance);
  }

  user(): User {
    return user(this.instance);
  }
}

export const IdentityFactoryAPI = (params: IClientConfigProps): IdentityAPI => new IdentityAPI(params);

import { FC, PropsWithChildren, ReactElement, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';

import { Text } from '../Text';
import { IPageProps } from './types';

export const Page: FC<PropsWithChildren<IPageProps>> = ({ children, ...props }): ReactElement => {
  const { t } = useTranslation('globals');

  const pageMotion = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.1 } },
    exit: { opacity: 0, transition: { duration: 0.1 } },
  };

  return (
    <Suspense fallback={<Text className="text-black-400 dark:text-white">{t('we_are_loading_you_page')}</Text>}>
      <motion.main initial="initial" animate="animate" exit="exit" variants={pageMotion} {...props}>
        {children}
      </motion.main>
    </Suspense>
  );
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ClientError } from '@shared/clients/http/commons/IClientError';
import { IdentityClient } from '@shared/clients/http/IdentityClient';

import { VerifyAccountRequest } from '../types/Users/requests';

export function useVerifyUserMutation() {
  return useMutation<void, AxiosError<ClientError>, VerifyAccountRequest>({
    mutationKey: ['mutate:create:verify-user'],
    mutationFn: data => IdentityClient.user().verify(data),
  });
}

import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Barn, CheckCircle, Lock, User } from '@phosphor-icons/react';

import { Avatar } from '@shared/components/Avatar';
import { Button } from '@shared/components/Button';
import { Divider } from '@shared/components/Divider';
import { useStepper } from '@shared/components/Stepper/hooks/useStepper';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';
import { HandleDate } from '@shared/utils/HandleDate';

import { AuthorizeAthleteStepFlow } from '@modules/invites/enums/AuthorizeAthleteStepFlow';
import { useAuthorizeRiderMutation } from '@modules/invites/hooks/useAuthorizeRiderMutation';
import { useFetchRiderInviteByCode } from '@modules/invites/hooks/useFetchRiderInviteByCode';
import { IRouteAthleteAuthorizeShareParams } from '@modules/invites/types/RoutesTypes';

export const AthleteAuthorizeShareAuthorization = (): ReactElement => {
  const { t } = useTranslation('invites');

  const { inviteCode } = useParams<keyof IRouteAthleteAuthorizeShareParams>();

  const { mutate: authorizeRiderByInviteCode, isPending } = useAuthorizeRiderMutation();
  const { data: riderInviteCode } = useFetchRiderInviteByCode();

  const { handleStepSelected } = useStepper();

  const invite = {
    companyImageUrl: riderInviteCode?.company.imageUrl,
    companyName: riderInviteCode?.company.name ?? t('loading'),
    riderImageUrl: riderInviteCode?.rider.imageUrl,
    riderName: riderInviteCode?.rider.name.split(' ')[0] ?? t('loading'),
  };

  const authorize = async (): Promise<void> => {
    if (inviteCode) {
      const date = new HandleDate()
        .setDate(new Date())
        .setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .getFormattedWithTimezone();

      authorizeRiderByInviteCode(
        { date, inviteCode },
        {
          onError: error => {
            toast(error.response?.data.message, { type: 'error' });
          },
          onSuccess: ({ isAuthorized }) => {
            if (isAuthorized) {
              handleStepSelected(AuthorizeAthleteStepFlow.SUCCESS);
            }
          },
        },
      );
    }
  };

  // Send to step CHECK_EMAIL when not have tokens to request
  useEffect(() => {
    const tokensStr = localStorage.getItem(Storage.TOKENS);

    if (!tokensStr) handleStepSelected(AuthorizeAthleteStepFlow.CHECK_EMAIL);
  }, [handleStepSelected]);

  // Check if rider is already shared with company, so send to step ALREADY_EXISTS
  useEffect(() => {
    if (riderInviteCode?.rider.isShared) handleStepSelected(AuthorizeAthleteStepFlow.ALREADY_EXISTS);
  }, [handleStepSelected, riderInviteCode?.rider]);

  return (
    <section className="flex w-full flex-col items-center justify-center">
      {/* Title */}
      <Text className="text-center text-lg font-bold text-black-400 lg:text-xl">
        {t('page_title_invite_register_authorization')}
      </Text>

      {/* Description */}
      <Text className="mb-6 mt-2 text-center text-sm leading-tight text-gray-500">
        {t('share_data_authorization_description')}
      </Text>

      {/* Profile images */}
      <div className="flex w-full items-center">
        <div className="flex flex-col items-center">
          <Avatar.Root mode="ROUNDED" size="sm">
            <Avatar.Content icon={User} url={invite.riderImageUrl} />
          </Avatar.Root>
          {/* <Text className="text-black-400">{invite.riderName}</Text> */}
        </div>

        <div className="flex flex-1 items-center">
          <hr className="h-[1px] flex-1 bg-black-400" />
          <CheckCircle size={24} className="block w-6 text-green-500" weight="fill" />
          <hr className="h-[1px] flex-1 bg-black-400" />
        </div>

        <div className="flex flex-col items-center">
          <Avatar.Root mode="ROUNDED" size="sm">
            <Avatar.Content icon={Barn} url={invite.companyImageUrl} />
          </Avatar.Root>
          {/* <Text className="text-black-400">{invite.companyName}</Text> */}
        </div>
      </div>

      <Divider mode="full" color="gray" className="my-4" />

      <div className="flex w-full flex-col gap-1 rounded-lg bg-green-500/20 p-4">
        <div className="flex items-center gap-2">
          <Lock size={16} className="text-black-400" />
          <Text className="text-sm text-black-400">{t('check_identity')}</Text>
        </div>

        <div className="flex items-center gap-2">
          <User size={16} className="text-black-400" />
          <Text className="text-sm text-black-400">{t('see_my_data_profile')}</Text>
        </div>
      </div>

      <Button className="mt-6" onClick={authorize} isLoading={isPending} textOnLoading={t('authorizing')}>
        {`${t('authorize')} ${invite.companyName}`}
      </Button>
    </section>
  );
};

import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@shared/components/Button';
import { InputPasswordForm } from '@shared/components/Form/HookForm';
import { InputLabel } from '@shared/components/InputLabel';
import { useStepper } from '@shared/components/Stepper/hooks/useStepper';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';

import { useAthleteAuthorizeFlow } from '@modules/invites/contexts/AthleteAuthorizeFlow/hooks/useAthleteAuthorizeFlow';
import { AuthorizeAthleteStepFlow } from '@modules/invites/enums/AuthorizeAthleteStepFlow';
import {
  AthleteAuthorizationShareCreateAccountSchema,
  IAthleteAuthorizationShareCreateAccountSchema,
} from '@modules/invites/validations/AthleteAuthorizationShareCreateAccountValidation';

import { useSignUpMutation } from '@modules/users/hooks/useSignUpMutation';

export const AthleteAuthorizeShareCreateAccount = (): ReactElement => {
  const { t } = useTranslation('invites');

  const { inviteDecoded } = useAthleteAuthorizeFlow();
  const { handleStepSelected } = useStepper();

  const { mutate: signUp, isPending } = useSignUpMutation();

  const form = useForm<IAthleteAuthorizationShareCreateAccountSchema>({
    resolver: zodResolver(AthleteAuthorizationShareCreateAccountSchema),
  });

  const handleSubmit = async ({ password }: IAthleteAuthorizationShareCreateAccountSchema): Promise<void> => {
    signUp(
      {
        email: inviteDecoded.riderEmail,
        name: inviteDecoded.riderName,
        password,
        profile: 'ATHLETE',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        onError: error => {
          toast(error.response?.data.message, { type: 'error' });
        },
        onSuccess: () => {
          handleStepSelected(AuthorizeAthleteStepFlow.VERIFY_ACCOUNT);
        },
      },
    );
  };

  return (
    <section className="flex w-full flex-col items-center justify-center gap-2">
      {/* Title */}
      <Text className="mb-4 text-center text-lg font-bold text-black-400 lg:mb-8 lg:text-xl">
        {t('page_title_invite_register_create_account')}
      </Text>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex w-full flex-col gap-3">
          <div className="flex flex-col">
            <InputLabel>{t('name')}</InputLabel>
            <Text className="text-sm text-black-400">{inviteDecoded.riderName}</Text>
          </div>

          <div className="flex flex-col">
            <InputLabel>{t('email')}</InputLabel>
            <Text className="text-sm text-black-400">{inviteDecoded.riderEmail}</Text>
          </div>

          <InputPasswordForm name="password" label={t('password')} />

          <Button type="submit" isLoading={isPending} textOnLoading={t('registering')}>
            {t('register_password')}
          </Button>
        </form>
      </FormProvider>
    </section>
  );
};

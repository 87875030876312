import { InternalAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

import { Storage } from '@shared/constants/Storage';

import { Tokens } from '@modules/auth/types/Auth/auth';

export class HandleRequestsFunctions {
  public static handleRequest = async (request: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    const headers = { ...request.headers } as RawAxiosRequestHeaders;

    if (!headers?.Authorization) {
      const tokens = localStorage.getItem(Storage.TOKENS);

      if (tokens && JSON.parse(tokens)) {
        const { accessToken, equitesToken }: Tokens = JSON.parse(tokens);

        Object.assign(request, {
          headers: {
            ...request.headers,
            Authorization: `Bearer ${accessToken}`,
            'x-Equites-Authorization': `Bearer ${equitesToken}`,
          },
        });
      }
    }

    return request;
  };
}

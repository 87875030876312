import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

import LogoAnimated from '@shared/assets/gifs/logo-animated-once-time.gif';
import { BackgroundSignIn } from '@shared/components/BackgroundSignIn/BackgroundSignIn';
import { CardContainer } from '@shared/components/Cards';
import { Page } from '@shared/components/Page';
import { PrivacyAndTerms } from '@shared/components/PrivacyAndTerms';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';

import { ConfirmForgotPasswordForm } from '@modules/auth/components/ForgotPassword/ConfirmForgotPasswordForm';
import { ForgotPasswordForm } from '@modules/auth/components/ForgotPassword/ForgotPasswordForm';
import { ResetPasswordForm } from '@modules/auth/components/ForgotPassword/ResetPasswordForm';

import { VerifyAccountForm } from '@modules/users/components/SignUp/VerifyAccountForm';

import { useAuth } from '../contexts/Auth/hooks/useAuth';
import { useForgotPasswordMutation } from '../hooks/useForgotPasswordMutation';

export const ForgotPassword = (): ReactElement => {
  const { t } = useTranslation('auth');

  const { chooseFormShowing, handleFormShowing } = useAuth();

  const { mutate: forgotPassword } = useForgotPasswordMutation();

  const onVerifySuccess = (): void => {
    forgotPassword(
      { email: sessionStorage.getItem(Storage.EMAIL_FORGOT_PASSWORD) as string },
      {
        onError: error => {
          toast(error.response?.data.message, { type: 'error' });
        },
        onSuccess: () => {
          handleFormShowing('CONFIRMATION_CODE_PASSWORD');
        },
      },
    );
  };

  // Remove email from sessionStorage when the form is closed
  useUnmount(() => {
    handleFormShowing('EMAIL_FORM');
    sessionStorage.removeItem(Storage.EMAIL_FORGOT_PASSWORD);
  });

  return (
    <Page className="flex h-full w-full items-center justify-center">
      <BackgroundSignIn />

      <CardContainer>
        {/* Logo */}
        <img src={LogoAnimated} alt="Equites" className="mb-2 h-12 w-auto lg:mb-4 lg:h-20" />

        {/* Title */}
        <Text className="text-md font-bold text-black-400 lg:text-xl">{t('forgot_password')}</Text>

        {chooseFormShowing === 'CONFIRM_ACCOUNT' && (
          <Text className="mt-2 text-sm text-black-400">{t('before_you_need_confirm_account')}</Text>
        )}

        {/* Form data */}
        <section className="mt-4 flex w-full lg:mt-8">
          {chooseFormShowing === 'EMAIL_FORM' && <ForgotPasswordForm />}
          {chooseFormShowing === 'CONFIRM_ACCOUNT' && <VerifyAccountForm onVerifySuccess={onVerifySuccess} />}
          {chooseFormShowing === 'CONFIRMATION_CODE_PASSWORD' && <ConfirmForgotPasswordForm />}
          {chooseFormShowing === 'SET_PASSWORD' && <ResetPasswordForm />}
        </section>

        <PrivacyAndTerms />
      </CardContainer>
    </Page>
  );
};

import * as z from 'zod';

export const SetPasswordSchema = z
  .object({
    password: z
      .string({ required_error: 'password_required' })
      .min(6, { message: 'password_min_length_six' })
      .regex(/([a-zA-Z]{1})/, { message: 'password_least_one_letter' }),
    passwordConfirmation: z.string({ required_error: 'password_confirmation_required' }),
  })
  .superRefine(({ password, passwordConfirmation }, ctx) => {
    if (password !== passwordConfirmation)
      ctx.addIssue({ path: ['passwordConfirmation'], code: 'custom', message: 'password_confirmation_different' });
  });

export type ISetPasswordSchema = z.infer<typeof SetPasswordSchema>;

import { AxiosInstance } from 'axios';

import { AuthorizeShare, RiderByInviteCode } from '@modules/invites/types/AthleteAuthorizeFlow/athleteAuthorize';
import {
  AuthorizeShareByInviteCodeRequest,
  FindRiderByInviteCodeRequest,
} from '@modules/invites/types/AthleteAuthorizeFlow/requests';

export class InvitesAthletes {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getRiderInviteByCode({ inviteCode }: FindRiderByInviteCodeRequest): Promise<RiderByInviteCode> {
    const response = await this.instance.get(`/riders/register/athletes/invites/${inviteCode}`);

    return response.data;
  }

  async authorizeRider(request: AuthorizeShareByInviteCodeRequest): Promise<AuthorizeShare> {
    const { inviteCode, ...data } = request;

    const response = await this.instance.post(`/riders/register/athletes/invites/${inviteCode}/authorize`, data);

    return response.data;
  }
}

export const invitesAthletes = (instance: AxiosInstance): InvitesAthletes => new InvitesAthletes(instance);

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { AthletesClient } from '@shared/clients/http/AthletesClient';
import { ClientError } from '@shared/clients/http/commons/IClientError';
import { CompaniesClient } from '@shared/clients/http/CompaniesClient';

import { SignUp } from '../services/SignUp';
import { SignUpRequest, SignUpResponse } from '../types/Users/requests';

export function useSignUpMutation() {
  return useMutation<SignUpResponse, AxiosError<ClientError>, SignUpRequest>({
    mutationKey: ['mutate:create:user'],
    mutationFn: data => {
      const application: { [key: string]: SignUp } = {
        ATHLETE: AthletesClient.signUp(),
        COMPANY: CompaniesClient.signUp(),
      };

      return application[data.profile].signUp(data);
    },
  });
}

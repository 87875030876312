import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { SpinnerGap } from '@phosphor-icons/react';

import { useStepper } from '@shared/components/Stepper/hooks/useStepper';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';
import { Base64Util } from '@shared/utils/Base64Util';

import { useAthleteAuthorizeFlow } from '@modules/invites/contexts/AthleteAuthorizeFlow/hooks/useAthleteAuthorizeFlow';
import { AuthorizeAthleteStepFlow } from '@modules/invites/enums/AuthorizeAthleteStepFlow';
import { IRouteAthleteAuthorizeShareParams } from '@modules/invites/types/RoutesTypes';

import { useValidateEmailAlreadyExistsMutation } from '@modules/users/hooks/useValidateEmailAlreadyExistsMutation';

export const AthleteAuthorizeShareCheckEmail = (): ReactElement => {
  const { t } = useTranslation('invites');

  const { inviteCode } = useParams<keyof IRouteAthleteAuthorizeShareParams>();

  const { handleInviteDecoded, inviteDecoded } = useAthleteAuthorizeFlow();
  const { handleStepSelected } = useStepper();

  const { mutate: validateEmailAlreadyExists } = useValidateEmailAlreadyExistsMutation();

  // Decode code from URL
  useEffect(() => {
    if (inviteCode) {
      const [companyId, riderId, riderName, riderEmail] = Base64Util.decode(inviteCode).split(':');
      handleInviteDecoded({ companyId, riderEmail, riderId, riderName });
    }
  }, [handleInviteDecoded, inviteCode]);

  // Send to correct step when email is already exists or not and account is verified or not
  useEffect(() => {
    if (Object.keys(inviteDecoded).length)
      validateEmailAlreadyExists(
        { email: inviteDecoded.riderEmail },
        {
          onError: error => {
            toast(error.response?.data.message, { type: 'error' });
          },
          onSuccess: ({ alreadyExists, isVerified }) => {
            if (alreadyExists && isVerified) {
              handleStepSelected(AuthorizeAthleteStepFlow.LOGIN);
            } else if (alreadyExists && !isVerified) {
              handleStepSelected(AuthorizeAthleteStepFlow.VERIFY_ACCOUNT);
            } else {
              handleStepSelected(AuthorizeAthleteStepFlow.CREATE_ACCOUNT);
            }
          },
        },
      );
  }, [handleStepSelected, inviteDecoded, validateEmailAlreadyExists]);

  useEffectOnce(() => {
    localStorage.removeItem(Storage.TOKENS);
  });

  return (
    <section className="flex w-full flex-col items-center justify-center gap-2">
      {/* Title */}
      <Text className="mb-4 text-center text-lg font-bold text-black-400 lg:mb-8 lg:text-xl">
        {t('page_title_invite_register_check_email')}
      </Text>

      <Text className="text-center text-black-400">{t('validating_your_information')}</Text>
      <SpinnerGap size={24} className="animate-spin text-black-400" />
    </section>
  );
};

import { AxiosInstance } from 'axios';

import {
  ConfirmForgotPasswordRequest,
  ForgotPasswordResponse,
  SetPasswordRequest,
  SignInRequest,
  SignInResponse,
} from '@modules/auth/types/Auth/requests';

export class Auth {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async confirmForgotPassword(data: ConfirmForgotPasswordRequest): Promise<void> {
    const response = await this.instance.post(`/forgot-password/confirm`, data);

    return response.data;
  }

  async forgotPassword(email: string): Promise<ForgotPasswordResponse> {
    const response = await this.instance.post(`/forgot-password`, { email });

    return response.data;
  }

  async resendCode(email: string): Promise<void> {
    const response = await this.instance.post(`/users/resend-code`, { email });

    return response.data;
  }

  async setPassword(data: SetPasswordRequest): Promise<void> {
    const response = await this.instance.post(`/set-password`, data);

    return response.data;
  }

  async signIn(data: SignInRequest): Promise<SignInResponse> {
    const response = await this.instance.post(`/sessions`, data);

    return response.data;
  }
}

export const auth = (instance: AxiosInstance): Auth => new Auth(instance);

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '../Text';

export const PrivacyAndTerms = (): ReactElement => {
  const { t } = useTranslation('globals');

  return (
    <div className="mt-3 flex items-center gap-4">
      <a target="_blank" rel="noopener noreferrer" href="https://equites.com.br/privacy-policy">
        <Text className="text-xs text-green-500 transition-colors hover:text-green-400">{t('privacy')}</Text>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://equites.com.br/terms">
        <Text className="text-xs text-green-500 transition-colors hover:text-green-400">{t('terms')}</Text>
      </a>
    </div>
  );
};

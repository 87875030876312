import * as z from 'zod';

export const AthleteAuthorizationShareCreateAccountSchema = z.object({
  password: z
    .string({ required_error: 'password_required' })
    .min(6, { message: 'password_min_length_six' })
    .regex(/([a-zA-Z]{1})/, { message: 'password_least_one_letter' }),
});

export type IAthleteAuthorizationShareCreateAccountSchema = z.infer<
  typeof AthleteAuthorizationShareCreateAccountSchema
>;

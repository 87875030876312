import { FC, memo } from 'react';
import { Outlet, RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnimatePresence } from 'framer-motion';

import { queryClient } from '@shared/clients/http/ReactQuery';
import { Fallback } from '@shared/components/Fallback';
import { Toast } from '@shared/components/Toast';
import { WhatsApp } from '@shared/components/WhatsApp';
import { EquitesAppProvider } from '@shared/contexts';
import { ErrorPage } from '@shared/routes/ErrorPage';

import { EquitesRoutes } from './EquitesRoutes';

const EquitesRoutesComponent: FC = () => {
  const publicRoutes = EquitesRoutes.publicRoutes.map(route => {
    return {
      path: route.path,
      element: <section className="flex h-full w-full bg-background-light">{route.element}</section>,
    };
  });

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <EquitesAppProvider>
          <ScrollRestoration />
          <Toast />
          <AnimatePresence>
            <Outlet />
          </AnimatePresence>
          <WhatsApp />
          <ReactQueryDevtools client={queryClient} />
        </EquitesAppProvider>
      ),
      errorElement: <ErrorPage />,
      children: [...publicRoutes],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<Fallback.Navigation />} />;
};

export const Routes = memo(EquitesRoutesComponent);

import { ReactElement, ReactNode } from 'react';

import { motion } from 'framer-motion';

interface ICardContainerProps {
  children: ReactNode;
}

export const CardContainer = ({ children }: ICardContainerProps): ReactElement => {
  return (
    <motion.div
      className="z-10 flex w-11/12 flex-col items-center rounded-lg bg-background-light/80 p-4 shadow-2xl lg:w-80 lg:p-8"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: 'spring', stiffness: 200, bounce: 0.6 }}
    >
      {children}
    </motion.div>
  );
};

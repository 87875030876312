/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';

import { RiderByInviteCode } from '../types/AthleteAuthorizeFlow/athleteAuthorize';

type IRouteParams = {
  inviteCode: string;
};

export function useFetchRiderInviteByCode() {
  const { inviteCode } = useParams<keyof IRouteParams>();

  return useQuery<RiderByInviteCode>({
    enabled: Boolean(inviteCode),
    queryKey: ['invites:riders:code', { inviteCode }],
    queryFn: () => CompaniesClient.invitesAthletes().getRiderInviteByCode({ inviteCode: inviteCode ?? '' }),
    staleTime: 1000 * 60 * 30, // 30 minutes
  });
}

import { FC, ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@shared/components/Button';
import { InputPasswordForm } from '@shared/components/Form/HookForm';
import { InputLabel } from '@shared/components/InputLabel';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';

import { useSetPasswordMutation } from '@modules/auth/hooks/useSetPasswordMutation';
import { ISetPasswordSchema, SetPasswordSchema } from '@modules/auth/validations/SetPasswordValidation';

export const ResetPasswordForm: FC = (): ReactElement => {
  const { t } = useTranslation('auth');

  const navigate = useNavigate();

  const form = useForm<ISetPasswordSchema>({ resolver: zodResolver(SetPasswordSchema) });

  const { mutate: setPassword, isPending } = useSetPasswordMutation();

  const handleSubmitConfirm = async (data: ISetPasswordSchema): Promise<void> => {
    setPassword(
      { email: sessionStorage.getItem(Storage.EMAIL_FORGOT_PASSWORD) ?? '', password: data.password },
      {
        onError: error => {
          toast(error.response?.data.message, { type: 'error' });
        },
        onSuccess: () => {
          navigate('/');
          toast(t('messages_password_changed_with_success'), { type: 'success' });
        },
      },
    );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmitConfirm)} className="flex w-full flex-col">
        <div className="flex flex-col gap-3">
          <div>
            <InputLabel>{`${t('your_access_email')}:`}</InputLabel>
            <Text className="text-sm text-black-400">{sessionStorage.getItem(Storage.EMAIL_FORGOT_PASSWORD)}</Text>
          </div>

          <InputPasswordForm name="password" label={t('your_password')} placeholder={t('your_password')} />

          <InputPasswordForm
            name="passwordConfirmation"
            label={t('confirm_your_password')}
            placeholder={t('confirm_your_password')}
          />
        </div>

        <Button type="submit" className="mt-4" isLoading={isPending} textOnLoading={t('saving')}>
          {t('save')}
        </Button>
      </form>
    </FormProvider>
  );
};

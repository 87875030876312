import { FC, ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';

import { zodResolver } from '@hookform/resolvers/zod';
import { addMinutes } from 'date-fns';

import { Button } from '@shared/components/Button';
import { InputForm } from '@shared/components/Form/HookForm';
import { InputLabel } from '@shared/components/InputLabel';
import { Text } from '@shared/components/Text';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';

import { useResendCodeMutation } from '@modules/auth/hooks/useResendCodeMutation';

import { useVerifyUserMutation } from '@modules/users/hooks/useVerifyUserMutation';
import { IVerifyAccountSchema, VerifyAccountSchema } from '@modules/users/validations/VerifyAccountValidation';

type Props = {
  onVerifySuccess: () => void;
};

export const VerifyAccountForm: FC<Props> = ({ onVerifySuccess }): ReactElement => {
  const { t } = useTranslation('users');

  const form = useForm<IVerifyAccountSchema>({ resolver: zodResolver(VerifyAccountSchema) });

  const { mutate: verifyAccount, isPending: isLoadingVerify } = useVerifyUserMutation();
  const { mutate: resendCode, isPending: isLoadingResend } = useResendCodeMutation();

  const { isRunning, minutes, seconds } = useTimer({ expiryTimestamp: addMinutes(new Date(), 1) });

  const getEmail = (): string | null => {
    const emailRegister = sessionStorage.getItem(Storage.EMAIL_REGISTER);
    const emailForgotPassword = sessionStorage.getItem(Storage.EMAIL_FORGOT_PASSWORD);

    return emailRegister || emailForgotPassword;
  };

  const getResendCodeLabel = (): string => {
    return isRunning
      ? `${t('resend_code')} ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      : t('resend_code');
  };

  const onResendCode = async (): Promise<void> => {
    const email = getEmail();

    if (email)
      resendCode(
        { email },
        {
          onError: error => {
            toast(error.response?.data.message, { type: 'error' });
          },
          onSuccess: () => {
            toast(t('messages_resend_code_success'), { type: 'success' });
          },
        },
      );
  };

  const handleSubmitVerifyAccount = async (data: IVerifyAccountSchema): Promise<void> => {
    const email = getEmail();

    if (email)
      verifyAccount(
        { code: data.code.toString(), email },
        {
          onError: error => {
            toast(error.response?.data.message, { type: 'error' });
          },
          onSuccess: () => {
            onVerifySuccess();
            toast(t('messages_account_verified_success'), { type: 'success' });
          },
        },
      );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmitVerifyAccount)} className="flex w-full flex-col">
        <section className="flex flex-col gap-4">
          <div>
            <InputLabel>{`${t('your_access_email')}:`}</InputLabel>
            <Text className="text-sm text-black-400">{getEmail()}</Text>
          </div>

          <InputForm name="code" label={t('verify_code')} placeholder={t('check_your_email')} />
        </section>

        <Button
          variant="tertiary"
          className="mt-2"
          disabled={isRunning}
          isLoading={isLoadingResend}
          onClick={onResendCode}
          textOnLoading={t('sending')}
        >
          {getResendCodeLabel()}
        </Button>

        <Button type="submit" className="mt-4" isLoading={isLoadingVerify} textOnLoading={t('checking')}>
          {`${t('send')}!`}
        </Button>
      </form>
    </FormProvider>
  );
};

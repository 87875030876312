/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ClientError } from '@shared/clients/http/commons/IClientError';
import { IdentityClient } from '@shared/clients/http/IdentityClient';

import { ForgotPasswordResponse } from '../types/Auth/requests';

export function useForgotPasswordMutation() {
  return useMutation<ForgotPasswordResponse, AxiosError<ClientError>, { email: string }>({
    mutationKey: ['mutate:create:forgot-password'],
    mutationFn: ({ email }) => IdentityClient.auth().forgotPassword(email),
  });
}

import { AxiosInstance } from 'axios';

import {
  GetEmailByExternalUsernameResponse,
  ValidateEmailAlreadyExistsRequest,
  ValidateEmailAlreadyExistsResponse,
  VerifyAccountRequest,
} from '@modules/users/types/Users/requests';
import { CurrentUserDTO } from '@modules/users/types/Users/users';

type IValidateEmailExistsResponse = Promise<ValidateEmailAlreadyExistsResponse>;
type IGetEmailByUsernameResponse = Promise<GetEmailByExternalUsernameResponse>;

export class User {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getCurrentUser(): Promise<CurrentUserDTO> {
    const response = await this.instance.get(`/users`);

    return response.data;
  }

  async getEmailByExternalUsername(externalUsername: string): IGetEmailByUsernameResponse {
    const response = await this.instance.get(`/users/external/${externalUsername}`);

    return response.data;
  }

  async validateEmailAlreadyExists(data: ValidateEmailAlreadyExistsRequest): IValidateEmailExistsResponse {
    const response = await this.instance.post(`/users/email/already-exists`, data);

    return response.data;
  }

  async verify(data: VerifyAccountRequest): Promise<void> {
    const response = await this.instance.post(`/users/verify`, data);

    return response.data;
  }
}

export const user = (instance: AxiosInstance): User => new User(instance);
